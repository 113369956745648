<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(firstName)="data">
            <span class="font-weight-bolder mb-12">{{ data.item.user.firstName+" "+ data.item.user.lastName }}</span>
        </template>

        <template #cell(type)="data">
            <b-badge pill :variant="getExamStatus(data.item.type)">
                {{ getExamStatusText(data.item.type) }}
            </b-badge>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive ? 'light-success' : 'light-danger'">
                {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
        </template>

        <template #cell(actions)="data">
            <b-button target="_blank" v-if="data.item.file!=null" block :href="data.item.file.url" variant="flat-primary" class="font-weight-bolder mb-12">Dosya Görüntüle</b-button>

            <b-button block variant="flat-success" class="font-weight-bolder mb-12" @click="setIsTrue(data.item.id)">Onayla</b-button>
        </template>

    </b-table>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.tr'
export default {
    components: {
        BOverlay,
        Cleave,
        BFormCheckbox,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BInputGroupPrepend,
        BInputGroup,
        BTooltip
    },
    data() {
        return {
            show: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'firstName', label: 'Öğrenci Adı Soyadı' },
                { key: 'type', label: 'Tip' },
                { key: 'definition', label: 'Bildiri' },
                { key: 'url', label: 'Bulunduğu Url' },
                { key: 'actions', label: 'Resim' },
            ],
            users: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Branch/Contacts");
            this.users = users.data.data;
        },
        getExamStatus(status) {
            switch (status) {
                case 'Claim':
                    return 'success'
                case 'Suggestion':
                    return 'info'
                case 'Error':
                    return 'danger'
            }
        },
        setIsTrue(uid) {

            this.$confirm({
                title: 'Onayla',
                message: `Onaylamak İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Onayla'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$http.get("Branch/ContactTrue/" + uid);
                        this.getData();
                    }
                }
            });

        },
        getExamStatusText(status) {
            switch (status) {
                case 'Claim':
                    return "İstek"
                case 'Error':
                    return "Hata"
                case 'Suggestion':
                    return "Öneri"
            }
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
